import * as React from 'react';
import * as R from 'ramda';
import { Trans } from 'react-i18next';
import Dropdown from '../../../../common/components/dropdown';
import Icon, { IconTypes } from '../../../../common/components/icon';

export type Props = {
  icon: any,
  title?: string,
  count?: number,
  action?: any,
};

export type SortingProps = {
  active: string,
  options: Array<{
    label: string,
    value: string,
    icon?: string,
  }>,
  onChange: (value: string) => void,
};

const FeedSectionHeaderComponent = ({ icon, title, count, action }: Props) => (
  <div className="FeedSectionHeader">
    {icon}
    <div className="FeedSectionHeader__Title">{title}</div>
    {count && <div className="FeedSectionHeader__Count">{count}</div>}
    {action && <div className="FeedSectionHeader__Action">{action}</div>}
  </div>
);

const FeedSectionHeaderOrderComponent = ({ active, options, onChange }: SortingProps) => {
  const activeOption = R.find(R.propEq('value', active), options);

  return (
    <div className="FeedSectionHeader">
      {activeOption && <div className="FeedSectionHeader__Title">{activeOption.label}</div>}
      <div className="FeedSectionHeader__Action">
        <Dropdown
          id="feed-section-header-ordering"
          alignRight
          toggle={(
            <div className="FeedSectionHeader__Sort">
              <Trans i18nKey="social:feed_section_header_sort" />
              <Icon type="sort" />
            </div>
          )}
        >
          {options.map((option) => (
            <Dropdown.Item
              key={option.label}
              icon={option.icon as IconTypes}
              active={active === option.value}
              onClick={() => onChange(option.value)}
            >
              {option.label}
            </Dropdown.Item>
          ))}
        </Dropdown>
      </div>
    </div>
  );
};

export default FeedSectionHeaderComponent;

export const FeedSectionHeaderOrder = FeedSectionHeaderOrderComponent;
