import Api from '../../../common/services/api';
import { ETimelineTypes } from '../definitions';

export const SOCIAL_RECEIVE_MEMBERS = 'social/RECEIVE_MEMBERS';

export default (offset = 0, filter = {}, limit = 30) => async (dispatch, getState) => {
  const { organisation: { selected: { id: organisationId } } } = getState();
  const { type, id } = filter;

  let baseUrl;

  if (type === ETimelineTypes.NETWORK || type === ETimelineTypes.FEED) {
    baseUrl = `/v2/networks/${id}/users`;
  } else if (type === ETimelineTypes.TEAM) {
    baseUrl = `/v1/teams/${id}/users`;
  } else if (type === ETimelineTypes.ORGANISATION) {
    baseUrl = `/v2/organisations/${id}/users`;
  } else if (type === ETimelineTypes.CHANNEL) {
    // $FlowFixMe
    baseUrl = `/v1/organisations/${organisationId}/channels/${id}/users`;
  }

  if (!baseUrl) return;

  const request = await Api.get(`${baseUrl}?offset=${offset}&limit=${limit}`);

  // Dispatch received data to update users_count if needed
  dispatch({
    type: SOCIAL_RECEIVE_MEMBERS,
    items: request.data,
    channel: filter,
    pagination: request.meta && request.meta.pagination,
  });

  return request;
};
