import Api from '../../../common/services/api';

import { NETWORK_RECEIVE_USERS } from './index';

export default (offset = 0, filter = {}, limit = 30) => async (dispatch, getState) => {
  const { network: { selected } } = getState();

  const query = Api.utils.toQuery({
    offset,
    limit,
    q: filter.query,
    status: filter.status,
    sort_by: filter.sortBy,
    can_chat: filter.canChat,
  });

  const request = await Api.get(`/v2/networks/${selected}/users?${query}`);

  const { data: items, meta: { pagination } } = request;

  dispatch({
    type: NETWORK_RECEIVE_USERS,
    items,
    pagination,
    strategy: pagination.offset > 0 ? 'append' : null,
  });

  return request;
};
