
import React from 'react';
import { Trans } from 'react-i18next';
import Card from '../../../../../common/components/card';
import Icon from '../../../../../common/components/icon';

const FeedBirthdayCard = ({ loggedUser }) => (
  <Card containerClassName="BirthdayMessage">
    <Card.Content>
      <img src="/static/images/ic-cake-96-px.png" alt="cake" />
      <div className="BirthdayMessage__Text">
        <Trans i18nKey="social:feed_item_birthday_congratulate" values={{ firstName: loggedUser.first_name }} />
      </div>
      {!loggedUser.settings.show_date_of_birth
        && (
        <div className="BirthdayMessage__Hidden">
          <Icon type="shield" />
          <Trans i18nKey="social:feed_item_birthday_only_you_can_see_this" />
        </div>
        )}
    </Card.Content>
  </Card>
);

export default FeedBirthdayCard;
