import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import Icon from '../../../../common/components/icon';
import Image from '../../../../common/components/image';
import { ETimelineTypes } from '../../definitions';

import type { Organisation, Network } from '../../../../common/types/objects';

export type Props = {
  organisation: Organisation,
  channel: Object,
  baseUrl: string,
  network?: Network,
};

const TimelineHeaderComponent = React.memo(({ organisation, network, channel, baseUrl }: Props) => {
  const { t } = useTranslation();

  // @ts-expect-error
  const theme = (channel.parent && channel.parent.theme) || organisation.theme || {};

  if (theme.header_background_url) theme.header_image_url = theme.header_background_url;

  // @ts-expect-error
  const FilterLink = ({ children, to, ...props }) => (
    <NavLink
      to={`${baseUrl.split(/\/filter|\/files/)[0]}${to}`}
      className="TimelineHeader__Bar__Filter" activeClassName="TimelineHeader__Bar__Filter--active"
      {...props}
    >
      {children}
    </NavLink>
  );

  return (
    <div className="TimelineHeader__Container">
      <div className="TimelineHeader" style={{ backgroundImage: theme.header_image_url && `url(${theme.header_image_url})` }}>
        <div className="TimelineHeader__ContentWrapper">
          <div className="TimelineHeader__Content">
            <div className="TimelineHeader__Content__Logo">
              {theme.logo_url
                // @ts-expect-error
                ? <img src={theme.logo_url} alt={channel.name} />
                : <Image src={organisation.brand_icon} alt={organisation.name} size={70} />}
            </div>
            <div className="TimelineHeader__Content__Info">
              {/* @ts-expect-error */}
              <h1>{channel.type === ETimelineTypes.FEED ? t('social:timeline_header_my_timeline') : channel.parent.name}</h1>
              <div className="TimelineHeader__Content__Info__Location">
                {/* @ts-expect-error */}
                {channel.type === ETimelineTypes.FEED && network && (
                  <>
                    <Icon type="place" />
                    {' '}
                    {network.name}
                  </>
                )}
                {/* @ts-expect-error */}
                {channel.type !== ETimelineTypes.FEED && (
                  <div className="TimelineHeader__Content__UsersCount">
                    {/* @ts-expect-error */}
                    {channel.users_count !== undefined && (
                      // @ts-expect-error
                      <Trans i18nKey="social:timeline_header_users_count" values={{ count: channel.users_count }} />
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {baseUrl && (
        <div className="TimelineHeader__Bar">
          <FilterLink exact to="" id="timeline_all">
            <Trans i18nKey="social:timeline_header_all_items" />
          </FilterLink>
          <FilterLink to="/filter/important" id="timeline_important">
            <Trans i18nKey="social:timeline_header_important_items" />
          </FilterLink>
          <FilterLink to="/files" id="timeline_files">
            <Trans i18nKey="social:timeline_header_files" />
          </FilterLink>
          {/* <FilterLink to="/filter/saved">
            <Trans i18nKey="social:timeline_header_saved_items" />
          </FilterLink> */}
        </div>
      )}
    </div>
  );
});

export default TimelineHeaderComponent;
