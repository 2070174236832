import * as React from 'react';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';
import PureList from '../../../../common/components/list';
import Item from './item';
import { ETimelineTypes } from '../../definitions';

const ChannelsComponent = ({
  loggedUser, organisation, network, teams, channels, selected, onSelectChannel, onHideChannel, onMuteChannel,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="balloon Channels">
        <Item
          item={{ name: t('social:channels_my_timeline'), image: loggedUser.profile_img }}
          selected={!!(selected && selected.type === ETimelineTypes.FEED)}
          onSelectChannel={() => onSelectChannel(ETimelineTypes.FEED, network.id)}
        />
        <Item
          id="organisation_messages"
          item={{ name: organisation.name, image: organisation.brand_icon }}
          selected={!!(selected && selected.type === ETimelineTypes.ORGANISATION)}
          onSelectChannel={() => onSelectChannel(ETimelineTypes.ORGANISATION, organisation.id)}
        />

        <h5 className="balloon__title">
          <span>{network.name}</span>
        </h5>
        <Item
          id="network_feed"
          item={{ ...network, image: '/static/images/community_icon.svg?url' }}
          selected={!!(selected && selected.type === ETimelineTypes.NETWORK)}
          onSelectChannel={() => onSelectChannel(ETimelineTypes.NETWORK, network.id)}
          onMute={network.is_member && ((muted) => onMuteChannel(ETimelineTypes.NETWORK, network.id, muted))}
        />
        <PureList
          id="network_group_messages"
          items={teams}
          renderRow={Item}
          rowProps={(team) => ({
            sub: true,
            selected: selected && selected.type === ETimelineTypes.TEAM && selected.id === team.id,
            onSelectChannel: () => onSelectChannel(ETimelineTypes.TEAM, team.id),
            onMute: team.is_member && ((muted) => onMuteChannel(ETimelineTypes.TEAM, team.id, muted)),
          })}
        />
      </div>

      <div className="balloon Channels">
        <h5 className="balloon__title">
          <span>{organisation.name}</span>
        </h5>
        <PureList
          id="organisation_group_messages"
          items={channels.map((channel) => R.assoc('image', channel.theme.logo_url || organisation.brand_icon, channel))}
          renderRow={Item}
          rowProps={(group) => ({
            selected: selected && selected.type === ETimelineTypes.CHANNEL && selected.id === group.id,
            onSelectChannel: () => onSelectChannel(ETimelineTypes.CHANNEL, group.id),
            onHideChannel: (hidden) => onHideChannel(ETimelineTypes.CHANNEL, group.id, hidden),
            onMute: group.settings.is_member && ((muted) => onMuteChannel(ETimelineTypes.CHANNEL, group.id, muted)),
          })}
        />
      </div>
    </>
  );
};

export default ChannelsComponent;
