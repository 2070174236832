import Api from '../../../common/services/api';
import { SOCIAL_RECEIVE_CONGRATULATIONS } from './index';

export default (_, filter) => async (dispatch, getState) => {
  const { network: { selected } } = getState();

  // in this case, id is the date
  const request = await Api.get(`/v1/networks/${selected}/birthdays/${filter.id}/comments`);

  dispatch({
    type: SOCIAL_RECEIVE_CONGRATULATIONS,
    message: filter.id,
    comments: request.data,
    related: request.meta ? request.meta.related : null,
  });

  return request;
};
