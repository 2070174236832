import * as R from 'ramda';
import Api from '../../../common/services/api';

export const NETWORK_RECEIVE_BIRTHDAYS = 'network/RECEIVE_BIRTHDAYS';

export default () => async (dispatch, getState) => {
  const { network: { selected } } = getState();

  const { data } = await Api.get(`/v1/networks/${selected}/birthdays`);

  return {
    data: R.pipe(
      R.mapObjIndexed((users, date) => ({ date, users })),
      R.values,
      R.sortBy(R.prop('date')),
    )(data),
  };
};
