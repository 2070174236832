import * as React from 'react';
import { Trans } from 'react-i18next';
import * as AnalyticsService from '../../../../common/services/analytics';
import { EEventNames } from '../../../../../client/analytics';
import Permission from '../../../../common/components/permission';
import List from '../../../../common/components/list';
import { EPermissions } from '../../../../common/definitions';
import { transformUrl } from '../../../../common/utils/url';
import { ShortcutResponse } from '@modules/social/types/objects';

type ShortcutItemProps = {
  item: ShortcutResponse,
  onOpen?: () => unknown,
};

const ShortcutItemComponent = React.memo<ShortcutItemProps>(({ item, onOpen }: ShortcutItemProps) => (
  <a className="Shortcut" href={transformUrl(item.web.url)} target="_blank" onClick={onOpen} rel="noreferrer">
    <div>
      <div className="Shortcut__Icon" style={{ backgroundColor: item.color }}>
        <img src={item.icon} alt={item.name} />
      </div>
      <div className="Shortcut__Name">{item.name}</div>
    </div>
  </a>
));

interface Props {
  shortcuts: ShortcutResponse[],
  onView: () => unknown,
}

export const ShortcutsWidget = ({ shortcuts, onView }: Props) => (
  <div className="balloon">
    <h5 className="balloon__title balloon__title--no-margin">
      <span>
        <Trans i18nKey="social:shortcuts" values={{ count: shortcuts.length }} components={[<small />]} />
      </span>
      <Permission name={EPermissions.ORGANISATION_ORGANISATIONS_UPDATE}>
        <div className="pull-right">
          <a onClick={onView}><Trans i18nKey="social:view_shortcuts" /></a>
        </div>
      </Permission>
    </h5>
    <List
      items={shortcuts}
      renderRow={ShortcutItemComponent}
      rowProps={{
        onOpen: () => AnalyticsService.track(EEventNames.OPENED_SHORTCUT),
      }}
    />
  </div>
);
