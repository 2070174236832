import moment from 'moment';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import Dropdown from '../../../../common/components/dropdown';
import Confirm from '../../../../common/components/confirm-button';
import { Button } from '../../../../common/components/button';
import * as EventActions from '../../../events/actions';
import type { EventForUserDto } from '../../../events/types';

interface Props {
  item: EventForUserDto,
  onOpen: (eventId: string) => unknown,
}

export const EventWidgetItem = ({ item, onOpen }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isGoing, setIsGoing] = useState(item.is_going);

  return (
    <div className="EventWidgetItem" onClick={() => onOpen(item.id)} role="link">
      <div className="EventWidgetItem__Image" style={{ backgroundImage: `url(${item.header_image_url})` }} />
      <div className="EventWidgetItem__Info">
        <small>
          { formatDate(item) }
        </small>
        <h5>{item.name.substring(0, 30)}</h5>
      </div>
      {
        item?.settings?.disable_guest_list ?
          null :
          (
            isGoing ?
            (
              <Dropdown
                alignRight
                toggle={(
                  <Button type="success" size="small" icon="check_circle" />
                )}
              >
                <Dropdown.Item
                  icon="close"
                  onClick={async () => {
                    await dispatch(EventActions.absentEvent(item.id));
                    setIsGoing(false);
                  }}
                >
                  <Trans i18nKey="social:not_going_to_event" />
                </Dropdown.Item>
              </Dropdown>
            ) : (
              <Dropdown
                alignRight
                toggle={(
                  <Button size="small" icon="help__filled" />
                )}
              >
                <Dropdown.Item
                  icon="check_circle"
                  onClick={async () => {
                    await dispatch(EventActions.attendEvent(item.id));
                    setIsGoing(true);
                  }}
                >
                  <Trans i18nKey="social:going_to_event" />
                </Dropdown.Item>
                <Confirm
                  danger
                  title={t('events:confirm_not_going_to_event')}
                  confirmText={t('events:not_going')}
                  onConfirm={async () => {
                    await dispatch(EventActions.absentEvent(item.id));
                    setIsGoing(false);
                  }}
                >
                  <Dropdown.Item icon="close">
                    <Trans i18nKey="social:not_going_to_event" />
                  </Dropdown.Item>
                </Confirm>
              </Dropdown>
            )
          )
      }
    </div>
  );
};

function formatDate(item: EventForUserDto) {
  const startTime = moment(item.start_time);
  const endTime = item.end_time && moment(item.end_time);

  if (item.is_full_day) {
    const date = startTime.format('MMM DD');
    if (endTime) {
      const withinOneDay = startTime.isSame(endTime, 'day');
      if (withinOneDay) {
        return date;
      }
      return (date + ' - ' + endTime.format('MMM DD'));
    }
    return date;
  }

  const date = startTime.format('MMM DD [·] HH:mm');
  if (endTime) {
    const withinOneDay = startTime.isSame(endTime, 'day');
    const format = withinOneDay ? 'HH:mm' : 'MMM DD [·] HH:mm';
    return (date + ` - ${endTime.format(format)}`);
  }
  return date;
}
