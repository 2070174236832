import Api from '../../../common/services/api';
// import type { Dispatch, StoreState } from '../../../common/types/store';
import { SOCIAL_RECEIVE_SCHEDULED_MESSAGES } from './index';

// export default (nextCursor: ?string, filter: Object, limit: number = 10) => async (dispatch: Dispatch, getState: () => StoreState) => {
export default (nextCursor, _, limit) => async (dispatch, getState) => {
  const { organisation: { selected: { id: organisationId } } } = getState();

  const query = Api.utils.toQuery({
    limit,
    cursor: nextCursor || true,
  });

  const request = await Api.get(`/v1/organisations/${organisationId}/messages/scheduled?${query}`);

  dispatch({
    type: SOCIAL_RECEIVE_SCHEDULED_MESSAGES,
    items: request.data,
    append: !!nextCursor,
    related: request.meta.related,
  });

  return request;
};
