import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { mergeNamesToString } from '../../../../common/utils/merged-names-text';
import type { LoggedUser, SimpleUser } from '../../../../common/types/objects';

interface Props {
  loggedUser: LoggedUser,
  birthdays: SimpleUser[],
  onView: () => unknown,
}

export const BirthdaysWidget = ({ loggedUser, birthdays, onView }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="balloon">
      <h5 className="balloon__title">
        <span><Trans i18nKey="social:birthdays" /></span>
        <img
          style={{ height: 21, position: 'relative', top: -2, margin: '-3px 0 -3px 8px' }}
          src="/static/images/ic-cake-96-px.png"
          alt="cake"
        />
        <div className="pull-right">
          <a onClick={onView}>
            <Trans i18nKey="social:view_birthdays" />
          </a>
        </div>
      </h5>
      {birthdays.length > 0
        ? t('social:birthdays_today', {
          count: birthdays.length,
          context: birthdays[0].id === loggedUser.id ? 'you' : 'other',
          names: mergeNamesToString(birthdays.map((user) => {
            if (user.id === loggedUser.id) return { ...user, full_name: t('social:birthdays_you') };

            return user;
          }), 3, 'full_name'),
        })
        : t('social:no_birthdays_today')}
      .
    </div>
  );
};
