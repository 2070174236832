import * as React from 'react';
import * as moment from 'moment';
import { Trans } from 'react-i18next';
import Dropdown from '../../../../common/components/dropdown';
import Icon from '../../../../common/components/icon';
import { EFileTypes, fileTypesStyles } from '../../../../common/definitions';
import { bytesToSize } from '../../../../common/utils/file';

const DocumentComponent = ({ item, className, onOpenMessage }) => {
  const classNames = ['Attachment', 'Attachment--document', 'Document'];
  if (className) classNames.push(className);

  let icon = fileTypesStyles[item.file_type];

  if (!icon) icon = fileTypesStyles[EFileTypes.UNKNOWN];

  return (
    <div className={classNames.join(' ')}>
      <img src={icon} alt={item.file_type} />
      <div className="Attachment__Info">
        <a className="Attachment__Name" href={item.path} target="_blank" rel="noreferrer">{item.file_name}</a>
        <div className="Attachment__Meta">
          {moment(item.created_at).fromNow()}
          <span className="Attachment__Meta__Divider">•</span>
          {bytesToSize(item.file_size)}
        </div>
      </div>
      <Dropdown
        id={`file-dropdown=${item.id}`}
        toggle={<Icon type="more_vert" />}
      >
        <Dropdown.Item onClick={() => onOpenMessage(item.message_id)}><Trans i18nKey="social:feed_files_open_message" /></Dropdown.Item>
        <Dropdown.Item onClick={() => window.open(item.path, '_blank')}><Trans i18nKey="social:feed_files_download" /></Dropdown.Item>
      </Dropdown>
    </div>
  );
};

export default DocumentComponent;
