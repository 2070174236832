import memoize from '../../../common/utils/memoize';
import * as messagesReducer from '../reducers/messages';

const getScheduledMessagesCount = (state) => state.social.scheduled.count;

export const count = memoize.createSelector(
  [getScheduledMessagesCount],
  (state, number) => number,
);

const getScheduledMessages = (state) => state.social.scheduled.items;

export const messages = memoize.createSelector(
  [getScheduledMessages],
  (state, ids) => ids
    .map((id) => messagesReducer.findById(state, id))
    .filter((message) => !!message),
);
