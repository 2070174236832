import moment from 'moment';
import Api from '../../../common/services/api';

export const NETWORK_RECEIVE_BIRTHDAYS_FOR_DATE = 'network/RECEIVE_BIRTHDAYS_FOR_DATE';

export default (date) => async (dispatch, getState) => {
  const { network: { selected } } = getState();

  const { data: items, meta } = await Api.get(`/v1/networks/${selected}/birthdays/${date}`);

  dispatch({
    type: NETWORK_RECEIVE_BIRTHDAYS_FOR_DATE,
    date,
    items,
    meta,
    isToday: moment(date).isSame(moment(), 'day'),
  });
};
