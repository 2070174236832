import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import * as R from 'ramda';
import { Trans, useTranslation } from 'react-i18next';
import { Popup } from 'semantic-ui-react';
import { useRouteMatch, withRouter } from 'react-router';
import AsyncList from '../../../../common/components/list/async';
import ProfileImage from '@common/components/profile-image';
import Profile from '../../../../common/components/profile';
import Modal from '../../../../common/components/modal';
import fetchMembersAction from '../../actions/fetch-members';
import { ETimelineTypes } from '../../definitions';
import * as usersSelector from '../../../core/selectors/users';
import { PopoverContent, useViewUser } from '../../../../common/components/profile/popover';

const getRowProps = (item: unknown) => ({
  size: 32,
  user: item,
  // enablePopover: true,
});

type OwnProps = {
  channel: typeof ETimelineTypes;
  onEnter?: () => unknown;
};

const mapDispatchToProps = {
  fetchMembers: fetchMembersAction,
};

const connector = connect(undefined, mapDispatchToProps);

type Props = OwnProps & ConnectedProps<typeof connector>;

// here we are using semantic ui's Popover because this popover
// source\shared\common\components\profile\popover.js
// relies on bootstrap's Overlay which is buggy
function MemberProfileImage(props: any) {
  const match = useRouteMatch();
  const isChannel = match.path === '/networks/:networkId/messages/:type/:id' &&
    // @ts-expect-error
    match?.params?.type === 'channel';
  const className = isChannel ? '' : 'pointer';
  return (
    <Popup
      className="popover ProfilePopover"
      trigger={
        <div><ProfileImage {...props} className={className} /></div>
      }
      flowing
      hoverable
      mouseEnterDelay={500}
      mouseLeaveDelay={500}
      position="left center"
      disabled={isChannel}
    >
      <PopoverContent {...props} />
    </Popup>
  );
}

const mapStateToProps = (state: any, props: any) => ({
  user: usersSelector.byId(state, props.user.id) || props.user,
  network: state.network.selected,
  loggedUserId: state.loggedUser.user.id,
});
const ConnectedMemberProfileImage = withRouter(
  connect(mapStateToProps)(MemberProfileImage),
);

function FullListColleague(props: any) {
  const match = useRouteMatch();
  const isChannel = match.path === '/networks/:networkId/messages/:type/:id' &&
    // @ts-expect-error
    match?.params?.type === 'channel';
  const onClick = useViewUser(props);
  return <Profile {...props} onClick={isChannel ? null : onClick} />;
}

const ConnectedFullListColleague = withRouter(
  connect(mapStateToProps)(FullListColleague),
);

export const MembersWidget = connector(({ channel, fetchMembers, onEnter }: Props) => {
  const { t } = useTranslation();

  const filter = R.pick(['type', 'id'], channel);

  // Because we already show network members for feed, no need to fetch them again
  // @ts-expect-error
  if (channel.type === ETimelineTypes.FEED) filter.type = ETimelineTypes.NETWORK;

  return (
    <div className="balloon">
      <AsyncList
        data={{
          filter,
          // @ts-expect-error
          onFetch: fetchMembers,
          limit: 12,
        }}
        containerClassName="Members"
        // @ts-expect-error
        renderRow={ConnectedMemberProfileImage}
        rowProps={getRowProps}
        ShowMoreComponent={null} // Don't render show more component
        // @ts-expect-error
        header={({ pagination }) => (
          <h5 className="balloon__title">
            <span>
              {/* @ts-expect-error */}
              {(channel.type === ETimelineTypes.FEED || channel.type === ETimelineTypes.NETWORK)
                ? t('social:members_title_colleagues')
                : t('social:members_title_members')}
              <small>{pagination.total_count}</small>
            </span>
            <div className="pull-right">
              <Modal
                title={(
                  // @ts-expect-error
                  (channel.type === ETimelineTypes.FEED || channel.type === ETimelineTypes.NETWORK)
                    ? t('social:members_title_colleagues')
                    : t('social:members_title_members')
                )}
                onEnter={onEnter}
                content={(
                  <AsyncList
                    data={{
                      filter,
                      // @ts-expect-error
                      onFetch: fetchMembers,
                    }}
                    // @ts-expect-error
                    renderRow={ConnectedFullListColleague}
                    rowProps={getRowProps}
                  />
                )}
              >
                <a><Trans i18nKey="social:members_show_all" /></a>
              </Modal>
            </div>
          </h5>
        )}
        placeholder={(
          <Trans i18nKey="social:members_placeholder" />
        )}
      />
    </div>
  );
});
